export const feTabs = {
  left: [
    {
      id: 0,
      tabName: "Live Search",
      mode: "SUGGESTIONS",
      uriExtra: "",
    },
  ],
  right: [
    {
      id: 2,
      tabName: "Exp Search",
      mode: "SUGGESTIONS",
      uriExtra: "/ranker",
    },
  ],
};

export const colors = {
  primary: "#E40046", // primary color for citymall logo, btns
  inputFocus: "#E4004633",
  primaryBg: "#f8f8f8", // used as the primary app bg color
  silver: "#C4C4C4", // used in btns, and fonts
  ceruleanBlue: "#3F49D0", // fb btn
  lima: "#55CD1D", // success, progress bar
  lightBlack: "rgba(0, 0, 0, 0.56)", // used in fonts
  mercury: "#E5E5E5",
  mercurylight: "#E1E1E1",
  pacificBlue: "#0099CA", // more needed amount txt
  white: "#ffffff",
  black: "#000",
  errorRed: "#C41A16",
  errorLightRed: "FF2E00",
  orange: "#FFB800",
  mediumBlack: "rgba(0, 0, 0, 0.86)",
  lighterBlack: "rgba(0, 0, 0, 0.1)", // for image border
  darkBackground: "#F6F7F9",
  extraLightBlack: "rgba(0, 0, 0, 0.3)",
  outlinedTintColor: "rgba(0, 0, 0, .38)",
  lightGray: "#f7f7f7",
  filterGray: "#FAFAFA",
  yellow: "#FFC700",
  shadow: "rgba(0, 0, 0, 0.08)",
  cancelledText: "#ff5722",
  pendingText: "#ff9800",
  deliveredText: "#55CD1D",
  dispatchedText: "#8bc34a",
  searchBg: "rgba(0, 0, 0, 0.05)",
  bgGray: "rgba(0, 0, 0, 0.05)",
  WABgColor: "#54C824",
  heading: "#324A5E",
  lg2: "#B11544",
  lg1Yellow: "#FFB800",
  lg2Yellow: "#D09600",
  success: "#00DD73",
  yourRankBg: "#EA5D5D",
  caribbeanGreen: "#00D373",
  profileGrad1: "#FF4D4D",
  profileGrad2: "#E51739",
  maskedBg: "rgba(0, 0, 0, 0.7)",
  plain_green: "#00AB59",
  plain_red: "#FF2E00",
  plain_amber: "#FF7A00",
  blank_grey: "rgba(0, 0, 0, 0.1)",
  lightBlack1: "rgba(0, 0, 0, 0.4)",
  mediumLightBlack: "rgba(0, 0, 0, 0.8)",
  modalBackground: "rgba(0,0,0,0.5)",
  borderBox: "rgba(0, 0, 0, 0.2)",
  black2: "#000000",
  blackBackground: "#333333",
  lineColor: "#F4F4F4",
  dark_grey: "#757575",
  dark_grey2: "#707070",
  chat_date: "#606060",
  white_text1: "#F1F3F6",
  supportTicketColor: "rgba(228, 0, 70, 0.1)",
  supportTicketColor2: "rgba(0,0,0,0.86)",
  supportTicketColor3: "rgba(0, 0, 0, 0.2)",
  opaqueGreen: "rgba(0, 0, 0, 0.5)",
  greyTone1: "#0000008f",
  orangeText: "#F25700",
  whiteShade: "#fefefe",
  grey_light_shade: "#efefef",
  full_info_bg: "rgba(235, 174, 59, 0.1)",
  lightGrey: "rgba(0, 0, 0, 0.36)",
  black_shade_36: "rgba(0, 0, 0, 0.36)",
  black_shade_6: "rgba(0, 0, 0, 0.06)",
  black_shade_04: "rgba(0, 0, 0, 0.04)",
  black_shade_85: "rgba(0, 0, 0, 0.85)",
  black_shade_02: "rgba(0, 0, 0, 0.02)",
  black_shade_6_2: "rgba(0,0,0,0.6)",
  silver_shade: "rgba(196, 196, 196, 0.5)",
  blue_shade_01: "#4398D1",
  tomato_shade_01: "#FF7759",
  golden_shade_05: "rgba(235, 174, 59, 0.5)",
  red_shade: "#FF2E00",
  light_grayish_pink: "#FEF2F6",
  blue_shade_1: "#2F80ED",
  light_blue: "rgba(0, 116, 201, 0.7)",
  greyTone2: "rgba(0,0,0,0.10)",
  borderColor: "#E6E6E6",
  oos_shadow: "rgba(0, 0, 0, 0.46)",
  light_green_1: "rgba(0, 171, 89, 0.05)",
  light_green: "rgba(0, 171, 89, 0.1)",
  bgGrayColor: "#F8F8F8",
  darkTeal: "#009788",
  lightOrange: "#F7ECE6",
  lightGreen1: "#F2FBF7",
  borderColor2: "#D9D9D9",
  bottomSheetColor: "#A5A2A5",
  green: "#27AE60",
  lightRed: "#FFEEEB",
  borderRed: "rgba(255, 46, 0, 0.6)",
  ratingWhite: "rgba(255, 255, 255, 1)",
  blurWhite: "rgba(255, 255, 255, 0.8)",
  e6: "#EEEEEE",
  activeRed: "#CB0341",
  paleYellow: "#FFF5DD",
  lightBlack_shade: "#242424",
  lightBlack_shade_A3: "#A3A3A3",
  border_green: "rgba(0, 171, 89, 0.4)",
  light_green2: "#4CBB30",
  light_orange: "#EBAA04",
  dark_orange: "#FF4D00",
  dark_orange_brown: "#A8690B",
  dark_golden: "#CC9403",
  tomato_shade_2: "#FF4B32",
  blue_shade_02: "#5CACF5",
  blue_shade_03: "#D4E7F8",
  blue_shade_04: "#F3F8FE",
  red: "red",
  light_blue1: "#E8EEF7",
  light_navy_blue: "#0069E4",
};
