import api from "./createAxios";
import { useEffect, useState } from "react";

const SuggestionItem = ({ item, onClickSuggestion }) => (
  <li className="list-group-item" onClick={() => onClickSuggestion(item)}>
    <div className="row">
      <div className="col-2">
        {item.image && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url("${item.image}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />
        )}
      </div>
      <div className="col-10">
        <div>{item.text}</div>
        {item.sub_text && (
          <small className="text-muted">in {item.sub_text}</small>
        )}
        <br />
        <button
          className="btn btn-light btn-sm"
          onClick={(e) => {
            navigator.clipboard.writeText(item.link_params);
            e.stopPropagation();
          }}
        >
          Copy
        </button>
      </div>
    </div>
  </li>
);

const Suggestions = ({ searchTerm, token, onClickSuggestion, tab }) => {
  const [suggestions, setSuggestions] = useState([]);
  const uri = `/admin/api/v1${tab.uriExtra}/suggest?search_term=${searchTerm}`;

  useEffect(() => {
    api
      .get(uri, { headers: { Authorization: `${token}` } })
      .then((res) => {
        setSuggestions(res.data.results);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [searchTerm]);

  return (
    <div className="col-12">
      <ul className="list-group">
        {suggestions.map((item, idx) => (
          <SuggestionItem
            item={item}
            key={`x-${idx}`}
            onClickSuggestion={onClickSuggestion}
          />
        ))}
      </ul>
    </div>
  );
};

export default Suggestions;
