import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import UncleJi from "./UncleJi";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast, Toaster, ToastBar } from "react-hot-toast";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <App />
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            className: "",
            duration: 3000,
            style: {
              background: "#EAE9E9",
              color: "#000",
              border: "1px solid #cfcdca",
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <span
                      onClick={() => toast.dismiss(t.id)}
                      style={{
                        cursor: "pointer",
                        marginRight: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      X
                    </span>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        ;
      </>
    ),
  },
  {
    path: "/uncleji",
    element: <UncleJi />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
