import React from "react";

const Mic = (props) => {
  const { height, width } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_102_25)">
        <path
          d="M19.7758 22.8459C22.5425 22.8459 24.7591 20.6126 24.7591 17.8459L24.7758 7.84595C24.7758 5.07928 22.5425 2.84595 19.7758 2.84595C17.0091 2.84595 14.7758 5.07928 14.7758 7.84595V17.8459C14.7758 20.6126 17.0091 22.8459 19.7758 22.8459ZM28.6091 17.8459C28.6091 22.8459 24.3758 26.3459 19.7758 26.3459C15.1758 26.3459 10.9425 22.8459 10.9425 17.8459H8.10913C8.10913 23.5293 12.6425 28.2293 18.1091 29.0459V34.5126H21.4425V29.0459C26.9091 28.2459 31.4425 23.5459 31.4425 17.8459H28.6091Z"
          fill="white"
        />
      </g>
      <defs>
        <clip-path id="clip0_102_25">
          <rect width="40" height="40" fill="white" />
        </clip-path>
      </defs>
    </svg>
  );
};

export default Mic;
