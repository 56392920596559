import React from 'react'

const NoProductsFound = () => {
  return (
    <div className='zero-products' style={{display:'flex', flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%", color:"grey"}}>
        <h2>Oops...</h2>
        <h4>No Products Found!</h4>
    </div>
  )
}

export default NoProductsFound