import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Mic from "../components/Mic";
import uncleJi from "../assets/images/uncleJi.png";
import recognisingGif from "../assets/images/recognisingGif.gif";
import { colors } from "../constants";
import useLongPress from "../hooks/useLongPress";
import "./uncle.css";
import { getVoiceResponse } from "./api";

const UncleJi = () => {
  const [suggestions, setSuggestions] = useState([
    "बेस्ट ऑफर क्या चल रहे है?",
    "कूपन को कैसे यूज़ करना है?",
    "1 पे 1 फ्री कौनसे प्रोडक्ट्स में है?",
    "2 घंटे के डील में क्या है?",
  ]);
  const [loading, setLoading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript && !isPressed) getGPTResponse(transcript);
  }, [transcript, isPressed]);

  const getGPTResponse = async (message) => {
    try {
      setLoading(true);
      const payload = { query: message, user_id: "manish" };
      const resp = await getVoiceResponse()(payload);
      console.log(JSON.stringify(resp.data, null, 2));
      alert(JSON.stringify(resp.data, null, 2));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      alert(e.message);
    }
  };

  const onLongPress = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
    setIsPressed(true);
  };
  const onLongPressCancel = () => {
    SpeechRecognition.stopListening();
    SpeechRecognition.abortListening();
    setIsPressed(false);
  };
  const onClick = () => {
    console.log("click is triggered");
  };
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 200,
  };
  const longPressEvent = useLongPress(
    onLongPress,
    onLongPressCancel,
    onClick,
    defaultOptions
  );

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const pretifyText = (text) => {
    if (!text) {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  };

  const onPressSuggestion = (suggestion) => {
    console.log(suggestion);
    getGPTResponse(suggestion);
  };

  const styles = {
    loader: {
      marginTop: -50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 99,
      width: "100vw",
      height: "100vh",
      backgroundColor: colors.blurWhite,
    },
    container: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: colors.black,
    },
    image: {
      height: "100%",
      width: "100%",
      backgroundColor: colors.blue_shade_04,
    },
    suggestionsDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 100,
    },
    suggestionText: {
      backgroundColor: colors.blue_shade_03,
      borderRadius: 20,
      padding: "5px 15px",
      margin: "10px 0",
      cursor: "pointer",
    },
    resultsAndMic: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    resultText: {
      backgroundColor: colors.white,
      borderRadius: 20,
    },
    micParentContainer: {
      backgroundColor: colors.blurWhite,
      borderRadius: 200,
      backdropFilter: "blur(2.5px)",
    },
    micContainer: {
      backgroundColor: colors.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 200,
      padding: 10,
      margin: 10,
    },
    micRecog: {
      width: 40,
      height: 40,
      borderRadius: 200,
    },
  };

  return (
    <div>
      {loading && (
        <div style={styles.loader}>
          <div class="spinner-border" />
        </div>
      )}
      <div style={styles.container}>
        <img src={uncleJi} alt="icon" />
        <h1 style={{ paddingTop: 15 }}>अंकल जी से पूछे</h1>
        <p style={{ paddingTop: 15 }}>आप पूछ सकते हैं, जैसे</p>
        <div style={styles.suggestionsDiv}>
          {suggestions.map((suggestion) => (
            <h4
              key={suggestion}
              style={styles.suggestionText}
              onClick={(_) => onPressSuggestion(suggestion)}
            >
              {suggestion}
            </h4>
          ))}
        </div>
        <div style={styles.resultsAndMic}>
          {transcript ? (
            <div style={styles.resultText}>
              <p>{pretifyText(transcript)}</p>
            </div>
          ) : (
            <>Press and hold mic button to speak</>
          )}
          <div style={styles.micParentContainer}>
            <button {...longPressEvent} style={styles.micContainer}>
              <Mic height={40} width={40} />
              {isPressed && (
                <img
                  src={recognisingGif}
                  alt="recognisingGif"
                  style={styles.micRecog}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UncleJi;
