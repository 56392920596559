import createAxios from "./createAxios";
const getApi = (val) => createAxios(val);

const getVoiceResponse =
  (axios = getApi()) =>
  (data) =>
    axios.get(
      `?query=${encodeURIComponent(data.query)}&user_id=${encodeURIComponent(
        data.user_id
      )}`
    );

export { getVoiceResponse };
