const SearchInput = ({
    tab,
    searchTerm,
    setSearchTerm,
    onFocusSearch
}) => {
    return (
        <div className="col-12">
            <input
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value, tab)}
                onFocus={() => onFocusSearch(tab)}
                placeholder='Search Here....'
            />
        </div>
    )
}

export default SearchInput;
