import { useCallback, useState, useEffect, useRef } from "react";
import "./App.css";
import SearchInput from "./SearchInput";
import SearchInputV2 from "./SearchInputV2";
import Suggestions from "./Suggestions";
import SearchResults from "./SearchResults";
import SearchResultsV2 from "./SearchResultsV2";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { feTabs } from "./constants";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [mode, setMode] = useState("DEFAULT");
  const [searchParams, setSearchParams] = useState("");
  const [token, setToken] = useState("null");
  const searchInputRef = useRef(null);

  const onSearch = (searchTerm, tab) => {
    setSearchTerm(searchTerm);
  };

  const onSearchFromBanner = (searchTerm, tab) => {
    setSearchTerm(searchTerm);
    setMode("SEARCH_ENTER");
  };

  const onFocusSearch = (tab) => {
    setMode(tab.mode);
  };

  const onClickSuggestion = (item) => {
    setMode("SEARCH_RESULTS");
    setSearchTerm(item.text);
    setSearchParams(item.link_params);
  };

  const onChangeToken = (e) => {
    console.log("token", e.target.value);
    setToken(e.target.value);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        setMode("SEARCH_ENTER");
        searchInputRef.current.focus();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div className="head-container">
      {Object.keys(feTabs).map((tab) => {
        return (
          <div className="container" key={tab}>
            <br />
            <Tabs>
              <TabList>
                {feTabs[tab].map((tab, index) => (
                  <Tab key={index}>{tab.tabName}</Tab>
                ))}
              </TabList>

              {feTabs[tab].map((tab, index) => {
                return (
                  <TabPanel key={tab.tabName}>
                    <div
                      id={`scrollable-div-${tab.id}`}
                      style={{ maxHeight: "85vh", overflow: "auto" }}
                    >
                      <div>
                        <div className="col-12">
                          <input
                            ref={searchInputRef}
                            type="text"
                            onChange={onChangeToken}
                            className="search-input"
                            placeholder="User Token"
                          />
                        </div>
                        <SearchInput
                          tab={tab}
                          searchTerm={searchTerm}
                          token={token}
                          setSearchTerm={onSearch}
                          onFocusSearch={onFocusSearch}
                        />
                      </div>
                      {mode === "SUGGESTIONS" && [0, 2].includes(tab.id) && (
                        <div>
                          <Suggestions
                            searchTerm={searchTerm}
                            token={token}
                            onClickSuggestion={onClickSuggestion}
                            tab={tab}
                          />
                        </div>
                      )}
                      {mode === "SEARCH_RESULTS" && [0, 2].includes(tab.id) && (
                        <div>
                          <SearchResults
                            searchParams={searchParams}
                            token={token}
                            tab={tab}
                            onSearch={onSearchFromBanner}
                          />
                        </div>
                      )}
                      {mode === "SEARCH_ENTER" && [0, 2].includes(tab.id) && (
                        <div>
                          <SearchResults
                            searchParams={`?search_term_raw=${searchTerm}&search_term=${searchTerm}`}
                            token={token}
                            tab={tab}
                            onSearch={onSearchFromBanner}
                          />
                        </div>
                      )}
                    </div>
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        );
      })}
    </div>
  );
}

export default App;
